import * as THREE from 'three'

export default class MathMate {
  static tmpMatrix4_1 = new THREE.Matrix4();
  static tmpVector3_1 = new THREE.Vector3();
  static tmpVector3_2 = new THREE.Vector3();
  static tmpVector3_3 = new THREE.Vector3();
  static zeroVector = new THREE.Vector3(0, 0, 0);
  static tmpEuler = new THREE.Euler();
  static tmpQuaternion_1 = new THREE.Quaternion();
  static tmpQuaternion_2 = new THREE.Quaternion();
  static tmpQuaternion_3 = new THREE.Quaternion();

  // Change position, rotation scale. Add to excising
  static setPosRotAnsScale (props, deltaProps) {
    MathMate.getOrReset(props)
    MathMate.getOrReset(deltaProps)

    MathMate.scale(props, deltaProps)
    MathMate.rotate(props, deltaProps)
    MathMate.position(props, deltaProps)
  }

  static position (object, refObject) {
    // Offset position
    object.p.add(refObject.p)
  }

  static scale (object, refObject) {
    // Scale size
    object.s.multiply(refObject.s)

    // Scale position
    MathMate.scalePosition(object.p, refObject.s)
  }

  static rotate (props, deltaProps) {
    // Rotate rotation

    props.r = MathMate.Quaternion.addFromArray(deltaProps.r, props.r)

    // Rotate position
    const pos = MathMate.tmpVector3_1.fromArray(props.p)
    const rot = MathMate.tmpQuaternion_1.fromArray(deltaProps.r)
    pos.applyQuaternion(rot)
    props.p = [pos.x, pos.y, pos.z]
  }

  static degArrayToQuaternionArray (degArray) {
    MathMate.tmpVector3_1.fromArray(degArray)
    MathMate.eulerToQ(MathMate.tmpVector3_1, MathMate.tmpQuaternion_1)
    return [MathMate.tmpQuaternion_1.x, MathMate.tmpQuaternion_1.y, MathMate.tmpQuaternion_1.z, MathMate.tmpQuaternion_1.w]
  };

  static rotateTheRotate (object, QuaternionArray) {
    // Rotate rotation
    startData.r = MathMate.Quaternion.addFromArray(startData.r, toAddRot)
  }

  static scalePosition (position, scale) {
    position.multiply(scale)
  }

  static quaternionAdd (q1, q2, targetQ) {
    q1.multiply(q2)
    if (targetQ) {
      targetQ.copy(q1)
      return targetQ
    } else {
      return [q1.x, q1.y, q1.z, q1.w]
    }
  }

  static distanceBetween (v1, v2) {
    MathMate.tmpQuaternion_1.fromArray(v1)
    MathMate.tmpQuaternion_2.fromArray(v2)
    return MathMate.tmpVector3_1.distanceTo(MathMate.tmpVector3_2)
  };

  static eulerToQ (degVector, targetQ) {
    const euler = new THREE.Euler(degVector.x * (Math.PI / 180), degVector.y * (Math.PI / 180), degVector.z * (Math.PI / 180))
    const quaternion = targetQ || new THREE.Quaternion()
    quaternion.setFromEuler(euler)
    return quaternion
  };

  static quaternionToEuler (quaternion) {
    return new THREE.Euler().setFromQuaternion(quaternion)
  };

  static arrayQuaternionToEulerArray (arrayQuaternion) {
    MathMate.tmpQuaternion_1.fromArray(arrayQuaternion)
    const euler = MathMate.tmpEuler.setFromQuaternion(MathMate.tmpQuaternion_1)
    return [euler.x, euler.y, euler.z]
  };

  static rotateVectorWithQuaternionAsArray (vector3Array, qArray) {
    MathMate.tmpVector3_1.fromArray(vector3Array)
    MathMate.tmpQuaternion_1.fromArray(qArray)
    MathMate.tmpVector3_1.applyQuaternion(MathMate.tmpQuaternion_1)
    return [
      MathMate.tmpVector3_1.x,
      MathMate.tmpVector3_1.y,
      MathMate.tmpVector3_1.z
    ]
  }

  static quaternionFromNormal (normal, quaternion) {
    quaternion = quaternion || new THREE.Quaternion()
    const axis = new THREE.Vector3()
    // vector is assumed to be normalized
    if (normal.y > 0.99999) {
      quaternion.set(0, 0, 0, 1)
    } else if (normal.y < -0.99999) {
      quaternion.set(1, 0, 0, 0)
    } else {
      axis.set(normal.z, 0, -normal.x).normalize()
      const radians = Math.acos(normal.y)
      quaternion.setFromAxisAngle(axis, radians)
    }
    return quaternion
  }

  static circularDistribution (offset, axis, numberOfItems) {
    const rv = {
      p: [],
      towards: [],
      lookAt: []
    }
    for (let index = 0; index < numberOfItems; index++) {
      MathMate.tmpVector3_3.fromArray([0, 0, 0])
      MathMate.tmpVector3_3[axis] = offset
      const ang = (360 / numberOfItems) * index
      let qArr
      if (axis === AXIS.X) {
        qArr = MathMate.degArrayToQuaternionArray([0, ang, 0])
      } else if (axis === AXIS.Y) {
        qArr = MathMate.degArrayToQuaternionArray([0, 0, ang])
      } else if (axis === AXIS.Z) {
        qArr = MathMate.degArrayToQuaternionArray([ang, 0, 0])
      }

      const rotPos = MathMate.rotateVectorWithQuaternionAsArray([MathMate.tmpVector3_3.x, MathMate.tmpVector3_3.y, MathMate.tmpVector3_3.z], qArr)
      rv.p.push(rotPos)
      MathMate.tmpVector3_2.fromArray(rotPos).normalize()
      rv.towards.push(qArr)

      const q = MathMate.quaternionFromNormal(MathMate.tmpVector3_2)
      rv.lookAt.push([q.x, q.y, q.z, q.w])
    }
    return rv
  }
}

export const AXIS = {
  X: 'x',
  Y: 'y',
  Z: 'z'
}
