import _u from './_u.js'
import Dao from './Dao/Dao.js'
import Scene from './Dao/Scene.js'
import ArtCircle from './Dao/clip/ArtCircle.js'
import Mate from './Dao/Mate.js'
import PlaceMate from './Dao/PlaceMate.js'

export default class App {
  appId = 'HiberGallery'; // Id of the containing div in index.html
  artData = []
  arrOfProps = []

  constructor (artData = []) {
    this.artData = artData
  }

  setup () {
    Dao.setup(this.appId, () => {
      // Create the environment
      PlaceMate.setup()

      // Create the gallery
      // Split json with images into smaller arrays in size 1 - 4 pieces
      const artGrp = Mate.arraySplit(this.artData, 1, 4, false)
      // How big radius does a gallery have, it will also be referenced to size of gallery, see above
      const radius = 36

      _u.each(artGrp, (art, index) => {
        let radius = art.length * 2.5
        const lim = 4.5 // Minimum size of a gallery
        if (radius < lim) radius = lim
        if (art.length) {
          // Push props of the gallery to an array
          this.arrOfProps.push({ name: 'Temple', p: [0, 0.7, 0], artData: art, radius, size: art.length })
        }
      })

      // Place art temples in a grid
      const no = Math.ceil(Math.sqrt(this.arrOfProps.length))
      Mate.placeInGrid(this.arrOfProps, radius * 1.2, (props) => {
        // Offset the galleries
        props.p[2] += (radius * no) * 0.6
        // Add to scene
        Scene.add(new ArtCircle(props))
      })

      Scene.draw()
    })
  }
}
