import Dao from './Dao.js'
import Dapi from './Dapi.js'

export default class Scene {
  static info = {
    id: 'Scene',
    name: 'Scene'
  };

  static threeScene = null;
  static add = (object) => {
    // Three and Dao has different directions on x and z, so it needs to inverted to be mirrored
    object.position.x = object.position.x * -1
    object.position.z = object.position.z * -1

    Scene.threeScene.add(object)
  };

  static isOnScene = (object) => {
    let rv = false
    const check = (object) => {
      if (object.parent) {
        check(object.parent)
      } else {
        if (object.type === 'Scene') {
          rv = true
        }
      }
    }
    check(object)
    return rv
  };

  static draw = () => {
    Dao.updateThree()
    // Traverse all meshes on scene
    Scene.threeScene.traverse(function (object) {
      if (object.isMesh && object.userData.props.geo) {
        if (Scene.isOnScene(object)) {
          // Get world position, rotation and scale
          const data = object.data()
          // Update existing objects
          if (object.userData.props.groupID) {
            Dapi.update(object.userData.props.groupID, {
              position: data.props.position,
              quaternion: data.props.quaternion,
              scale: data.props.scale
            })
          } else { // Or insert new
            object.userData.props.groupID = Dapi.add(data.id, data.props)
          }
        }
      }
    })
  };
}
