export class RandomSeed {
  static seed = null;
  static seedVal = null;
  static reset = () => {
    RandomSeed.seedVal = RandomSeed.seed
  };

  static setup = () => {
    let seed = parseInt(RandomSeed.getQuery('seed'))
    if (!seed) {
      seed = RandomSeed.getSeed()
      RandomSeed.setQuery('seed', seed)
    } else {
      RandomSeed.seed = seed
    }
    if (RandomSeed.seed === -1) {
      RandomSeed.seedVal = RandomSeed.getSeed()
    }
    RandomSeed.seedVal = RandomSeed.seed
    return seed
  };

  static getSeed = () => {
    return Math.floor(Math.random() * (999999 - 100000 + 1) + 9999)
  };

  static getValue = () => {
    if (!RandomSeed.seed) RandomSeed.setup()
    if (RandomSeed.seed === -1) return Math.random()
    RandomSeed.seedVal = (RandomSeed.seedVal * 9301 + 49297) % 233280
    const rnd = RandomSeed.seedVal / 233280
    return 0 + rnd * (1 - 0)
  };

  static range = (from, to) => {
    return Math.floor(RandomSeed.getValue() * (to - from + 1) + from)
  };

  static getQuery = (param) => {
    return new URLSearchParams(window.location.search).get(param)
  };

  static setQuery = (param, value) => {
    const qp = new URLSearchParams(window.location.search)
    qp.set(param, value)
    history.replaceState(null, null, '?' + qp.toString())
  }
};
