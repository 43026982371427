import React from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './ExitGallery.module.sass'

const ExitGallery = ({ onTap }) => {
  return (
    <div
      className={styles.box}
      onTouchEnd={() => onTap()}
    >
      <img className={styles.icon} src="/icons/quit.svg" />
    </div>
  )
}

ExitGallery.propTypes = {
  onTap: PropTypes.func
}

export default ExitGallery
