import React, { forwardRef, useImperativeHandle, useState } from 'react' // eslint-disable-line no-use-before-define
import classNames from 'classnames/bind'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'

// @ts-expect-error Conversion from .sass to .css done during build
import * as stylesDesktop from './Toast.desktop.module.sass'
// @ts-expect-error Conversion from .sass to .css done during build
import * as stylesMobile from './Toast.mobile.module.sass'

const styles = isMobile ? stylesMobile : stylesDesktop
const cn = classNames.bind(styles)

// eslint-disable-next-line
const Toast = forwardRef(({ iconPath, text }, ref) => {
  const [animation, setAnimation] = useState(false)

  useImperativeHandle(ref, () => ({
    trigger: () => {
      setAnimation(false)
      setTimeout(() => setAnimation(true), 100)
    }
  }))

  return (
    <div className={styles.row}>
      <div className={cn('box', { animation })}>
        <img className={styles.icon} src={iconPath} />
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  )
})

// TODO: Investigate prop types for forward refs
Toast.displayName = 'Toast'
Toast.propTypes = {
  iconPath: PropTypes.string,
  text: PropTypes.string
}

export default Toast
