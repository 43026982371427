import _u from '../_u.js'
import extendThreeJS from './extendThreeJS.js'
import { RandomSeed } from './RandomSeed.js'
import Scene from './Scene.js'
import ThreeMate from './ThreeMate.js'
import { createGameModule } from '../../../hiber/createGameModule.js'

const WASM_PATH = './'
// const WASM_PATH = process.env.GATSBY_CDN_BASE_URL

export default class Dao {
  static scene = null;
  static three = null;
  static API = null;

  static setup (appId, callback) {
    Dao.mainWrapper = document.querySelector('#' + appId)
    Dao.three = new ThreeMate()
    Dao.gameApi = null
    Dao.scene = Dao.three.scene
    Scene.threeScene = Dao.three.scene

    extendThreeJS()

    _u.on('controller_updated', Scene.draw)
    const canvas = document.getElementById('canvas')
    createGameModule(document.getElementById('canvas'), WASM_PATH).then((gameApi) => {
      canvas.dispatchEvent(new Event('ready'))
      window.DAO = Dao.gameApi
      Dao.gameApi = gameApi
      // console.clear();
      callback(gameApi)
      _u.fire('dao_loaded')
      _u.fire('run-deselect')
    })
  }

  static rnd = () => {
    return RandomSeed.getValue()
  };

  static updateThree = () => {
    Dao.three.update()
  }

  static dispose (mesh) {
    mesh.geometry.dispose()
    mesh.material.dispose()
    if (mesh.parent) mesh.parent.remove(mesh)
  }
}
