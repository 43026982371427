import React from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './Fullscreen.module.sass'

const Fullscreen = ({ fullscreen, onTap }) => {
  return (
    <div
      className={styles.box}
      onTouchEnd={() => onTap()}
      onClick={() => onTap()}
    >
      <img className={styles.icon} src={`/icons/fullscreen_${fullscreen ? 'exit' : 'enter'}.svg`} />
    </div>
  )
}

Fullscreen.propTypes = {
  onTap: PropTypes.func,
  fullscreen: PropTypes.bool
}

export default Fullscreen
