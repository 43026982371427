import React from 'react' // eslint-disable-line no-use-before-define
import classNames from 'classnames/bind'
import { isMobile } from 'react-device-detect'
import { isPortrait } from '../../lib/util'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './StartPrompt.module.sass'

const cn = classNames.bind(styles)

const StartPrompt = () => {
  const text = isMobile ? 'TAP TO START' : 'CLICK TO START'
  const portrait = isPortrait()
  return (
    <div className={styles.start}>
      <div className={
        cn('text', {
          portrait: isMobile && portrait,
          landscape: isMobile && !portrait
        })}>{ text }</div>
    </div>
  )
}

export default StartPrompt
