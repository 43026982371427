import _u from '../_u.js'
import MathMate from './MathMate.js'
import runAxis from './runAxis.js'
import * as THREE from 'three'

export default class DaoObject extends THREE.Mesh {
  constructor (props = {
    p: null,
    r: null,
    s: null,
    c: null,
    id: null,
    geo: null,
    prefab: null,
    name: null,
    type: null,
    imgSrc: null,
    dontMap: null
  }) {
    super()
    props.id = props.id || props.name ? _u.pathToId(props.name) + '_' + _u.getIndex() : _u.getId()
    props.prefab = props.prefab || 'cube_01'
    props.dontMap = props.dontMap || false
    props.name = props.name || (props.geo ? props.geo : props.prefab) + '_' + _u.getIndex()
    this.userData.props = props
    this.setProps(this)
    this.params = props
    this.bBox = new THREE.Box3()
  }

  getBBox = () => {
    this.bBox.setFromObject(this)
    return this.bBox
  };

  data = () => {
    const tmpPosV3 = new THREE.Vector3()
    const tmpQ = new THREE.Quaternion()
    const tmpScaleV3 = new THREE.Vector3()

    this.getWorldPosition(tmpPosV3)
    this.getWorldQuaternion(tmpQ)
    this.getWorldScale(tmpScaleV3)

    // Three and Dao has different directions on x and z, so it needs to inverted to be mirrored

    const pData = {
      id: this.userData.props.prefab,
      props: {
        position: {
          x: tmpPosV3.x * -1,
          y: tmpPosV3.y,
          z: tmpPosV3.z * -1
        },
        quaternion: {
          x: tmpQ.x * -1,
          y: tmpQ.y,
          z: tmpQ.z * -1,
          w: tmpQ.w
        },
        scale: {
          x: tmpScaleV3.x,
          y: tmpScaleV3.y,
          z: tmpScaleV3.z
        }
      }
    }

    if (this.userData.props.imgSrc) {
      pData.props.imgSr = this.userData.props.imgSrc
    }

    return pData
  };

  setProps (mesh) {
    mesh.userData.props = this.fillUpEmptyProps(mesh.userData.props)
    mesh.position.fromArray(mesh.userData.props.p)
    mesh.quaternion.fromArray(mesh.userData.props.r)
    mesh.scale.fromArray(mesh.userData.props.s)
    if (mesh.userData.props.name) mesh.name = mesh.userData.props.name
  }

  fillUpEmptyProps (props) {
    // See if rotation is set with Euler
    if (props.r && props.r.length === 3) {
      props.r = MathMate.degArrayToQuaternionArray(props.r)
    }
    if (props.s && props.s.length === 1) {
      props.s = [props.s, props.s, props.s]
    }
    // Set default props when empty
    const defaultProps = {
      p: [0, 0, 0],
      r: [0, 0, 0, 1],
      s: [1, 1, 1],
      c: 0xffff00
    }
    return {
      ...defaultProps,
      ...props
    }
  }

  add (object) {
    object.position.x = object.position.x * -1
    object.position.z = object.position.z * -1
    THREE.Mesh.prototype.add.call(this, object) // call superclass method

    // If parent, this, has dontMap = false, copy it to the child object.
    this.userData.props.dontMap = object.userData.props.dontMap
  }

  setTo = (propName, axis, val) => {
    if (propName === 'r') {
      this.rotation[axis] = val * (Math.PI / 180)
    } else if (propName === 'q') {
      this.quaternion[axis] = val * (Math.PI / 180)
    } else if (propName === 'p') {
      runAxis(this.position, axis, val)
    } else if (propName === 's') {
      runAxis(this.scale, axis, val)
    }
  }

  addTo = (propName, axis, val) => {
    if (propName === 'r') {
      this.rotation[axis] += val * (Math.PI / 180)
    } else if (propName === 'q') {
      this.quaternion[axis] += val * (Math.PI / 180)
    } else if (propName === 'p') {
      runAxis(this.position, axis, val, true)
    } else if (propName === 's') {
      runAxis(this.scale, axis, val, true)
    }
  }
}
