import _u from '../_u.js'
import { RandomSeed } from './RandomSeed.js'

export default class Mate {
  // Split an array into smaller random groups of arrays.
  static arraySplit (array, start, end, shuffle) {
    const rv = []
    let clone = _u.clone(array)
    if (shuffle) clone = _u.shuffleArray(clone)
    const doSplit = () => {
      const len = RandomSeed.range(start, end)
      const slicedArray = clone.slice(0, len)
      rv.push(slicedArray)
      clone.splice(0, len)
      if (clone.length >= end) {
        doSplit()
      } else {
        rv.push(clone)
      }
    }

    doSplit()
    return rv
  }

  // Usage
  /*
  let arrOfProps = [];
  _u.each(this.params.artData, (ad, index) => {
    arrOfProps.push({
      p: [-index * gapSize, yPos, 0],
      s: [scale * ad.ratio, scale, 1],
      src: ad.src
    })
  })

  Mate.placeInCircle(arrOfProps, radius, (props) => {
    this.add(new ArtPiece1(props));
  })
  */

  static placeInCircle = (arrOfProps, radius, fn, params = {}) => {
    const itemRotationOffset = params.itemRotationOffset || 0
    const circleRotationOffset = params.circleRotationOffset || 0
    const degSteps = 360 / arrOfProps.length
    const rotK = 90 + itemRotationOffset

    _u.each(arrOfProps, (props, index) => {
      const nPos = _u.rotateAroundAPoint(0, 0, radius, 0, degSteps * index + circleRotationOffset)
      props.p[0] = nPos[0]
      props.p[2] = nPos[1]
      props.r = [0, degSteps * index + rotK, 0]
      fn(props)
    })
  };

  static placeInGrid = (arrOfProps, dim, fn, params = {}) => {
    dim = _u.defVal(dim, 5)
    const no = Math.ceil(Math.sqrt(arrOfProps.length))
    const offsetX = (dim * no) / 2
    const offsetZ = 0
    let index = 0

    for (let indexX = 0; indexX < no; indexX++) {
      for (let indexZ = 0; indexZ < no; indexZ++) {
        const props = arrOfProps[index]
        if (props) {
          props.p[0] = indexX * dim - offsetX
          props.p[2] = indexZ * dim - offsetZ
          props.p[0] += dim / 2 - props.size
          props.p[2] -= dim - props.size
          fn(props)
        }
        index++
      }
    }
  };

  // WIP
  static placeInSpiral = (arrOfProps, radius, fn, params = {}) => {
    const itemRotationOffset = params.itemRotationOffset || 0
    const circleRotationOffset = params.circleRotationOffset || 0
    const degSteps = params.degSteps || 40
    const radiusInc = _u.defVal(params.radiusInc, 5)
    const rotK = 90 + itemRotationOffset

    _u.each(arrOfProps, (props, index) => {
      const nPos = _u.rotateAroundAPoint(0, 0, radius + index * radiusInc, 0, degSteps * index + circleRotationOffset)
      props.p[0] = nPos[0]
      props.p[2] = nPos[1]
      props.r = [0, degSteps * props.size * index + rotK, 0]
      fn(props)
    })
  };
}
