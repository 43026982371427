import _u from '../_u.js'
import * as THREE from 'three'

const extendThreeJS = () => {
  THREE.Object3D.prototype.deepClone = function (recursive) {
    return new this.constructor().deepCopy(this, recursive)
  },
  THREE.Object3D.prototype.deepCopy = function (source, recursive) {
    if (recursive === undefined) recursive = true

    this.up.copy(source.up)

    this.position.copy(source.position)
    this.quaternion.copy(source.quaternion)
    this.scale.copy(source.scale)

    this.matrix.copy(source.matrix)
    this.matrixWorld.copy(source.matrixWorld)
    if (source.material) {
      // changed
      this.material = source.material.clone()
    }
    if (source.geometry) {
      // changed
      this.geometry = source.geometry.clone()
    }
    this.matrixAutoUpdate = source.matrixAutoUpdate
    this.matrixWorldNeedsUpdate = source.matrixWorldNeedsUpdate

    this.layers.mask = source.layers.mask
    this.visible = source.visible

    this.castShadow = source.castShadow
    this.receiveShadow = source.receiveShadow

    this.frustumCulled = source.frustumCulled
    this.renderOrder = source.renderOrder

    this.userData = JSON.parse(JSON.stringify(source.userData))

    // Set unique name and id
    this.userData.props.id = _u.getId()
    this.userData.props.name = this.userData.props.id
    this.name = this.userData.name

    if (recursive === true) {
      for (let i = 0; i < source.children.length; i++) {
        const child = source.children[i]
        this.add(child.deepClone()) // changed
      }
    }

    return this
  }

  // function isPointOnLine(pointA, pointB, pointToCheck) {
  //   var c = new THREE.Vector3();
  //   c.crossVectors(pointA.clone().sub(pointToCheck), pointB.clone().sub(pointToCheck));
  //   console.log(c.length());
  //   return !c.length();
  // }

  // THREE.isPointOnLineAndBetweenPoints = function (pointA, pointB, pointToCheck) {
  //   if (!isPointOnLine(pointA, pointB, pointToCheck)) {
  //     return false;
  //   }
  //   let d = pointA.distanceTo(pointB);
  //   return pointA.distanceTo(pointToCheck) < d && pointB.distanceTo(pointToCheck) < d;
  // }
}

export default extendThreeJS
