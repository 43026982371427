import Dao from './Dao.js'
import globals from '../../../globals'

export default class Dapi { // Dao API
  static register = {
    animations: {}
  };

  static addImage = (groupID, src) => {
    const cubeObjID = Dao.gameApi.worldGetObjectsInGroup(groupID)[0]
    Dao.gameApi.applyPropertyToObject(
      cubeObjID, {
        type: 'REMOTE_TEXTURE',
        data: {
          textureUrl: src
        }
      },
      false)
  };

  static add = (id, props) => {
    const groupID = Dao.gameApi.worldPlacePrefab(id, props)
    if (props.imgSr) {
      globals.assets.push(props)
      Dapi.addImage(groupID, props.imgSr)
    }
    return groupID
  };

  static update = (groupID, props) => {
    Dao.gameApi.applyPropertyToGroup(
      groupID, {
        type: 'TRANSFORM',
        data: {
          transform: {
            pos: [props.position.x, props.position.y, props.position.z],
            rot: [props.quaternion.x, props.quaternion.y, props.quaternion.z, props.quaternion.w],
            scale: [props.scale.x, props.scale.y, props.scale.z]
          }
        }
      },
      false)
  };

  dispose () {}
}
