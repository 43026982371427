import Hiberworld from './Hiberworld.js'

export function createGameModule (canvasEl, rootWasmPath = './') {
  if (!canvasEl) {
    throw new Error('Canvas element needs to be supplied')
  }

  const collectWebglInfo = () => {
    // Collect info
    const canvas = canvasEl
    let glContext = canvas.getContext('webgl2')
    if (!glContext) {
      glContext = canvas.getContext('webgl')
    }

    const unMaskedInfo = {
      renderer: 'unknown',
      vendor: 'unknown'
    }

    const dbgRenderInfo = glContext.getExtension('WEBGL_debug_renderer_info')
    if (dbgRenderInfo != null) {
      unMaskedInfo.renderer = glContext.getParameter(dbgRenderInfo.UNMASKED_RENDERER_WEBGL)
      unMaskedInfo.vendor = glContext.getParameter(dbgRenderInfo.UNMASKED_VENDOR_WEBGL)
    }

    if (window.TrackJS) {
      window.TrackJS.addMetadata('glVersion', glContext.getParameter(glContext.VERSION))
      window.TrackJS.addMetadata('renderer', unMaskedInfo.renderer)
      window.TrackJS.addMetadata('vendor', unMaskedInfo.vendor)
    }

    if (window.Sentry) {
      const webglInfo = {
        version: glContext.getParameter(glContext.VERSION),
        renderer: unMaskedInfo.renderer,
        vendor: unMaskedInfo.vendor
      }

      Sentry.setContext('webgl_info', {
        glVersion: webglInfo.version,
        renderer: webglInfo.renderer,
        vendor: webglInfo.vendor
      })

      Sentry.setTag('webgl.version', webglInfo.version)
      Sentry.setTag('webgl.renderer', webglInfo.renderer)
      Sentry.setTag('webgl.vendor', webglInfo.vendor)
    }
  }

  const fetchXMLHTTPRequest = async (url, progressCallback) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'arraybuffer'
      xhr.onprogress = function (progress) {
        if (progressCallback) {
          progressCallback(progress.loaded / progress.total)
        }
      }
      xhr.onload = function xhr_onload () {
        if (xhr.status == 200 || (xhr.status == 0 && xhr.response)) {
          // file URLs can return 0
          resolve(xhr.response)
          return
        }
        reject(xhr.status)
      }
      xhr.onerror = e => {
        const error = new Error('XMLHTTPRequestError')
        error.data = e
        reject(error)
      }
      xhr.send(null)
    })
  }

  const progressResponse = (response, progressHandler) => {
    const contentLength = response.headers.get('Content-Length')
    const total = parseInt(contentLength, 10)
    let loaded = 0

    const res = new Response(new ReadableStream({
      async start (controller) {
        const reader = response.body.getReader()
        for (;;) {
          const { done, value } = await reader.read()

          if (done) {
            progressHandler && progressHandler(total, total)
            break
          }

          loaded += value.byteLength
          progressHandler && progressHandler(loaded, total)
          controller.enqueue(value)
        }
        controller.close()
      }
    }, {
      status: response.status,
      statusText: response.statusText
    }))

    // Make sure to copy the headers!
    // Wasm is very picky with it's headers and it will fail to compile if they are not
    // specified correctly.
    for (const pair of response.headers.entries()) {
      res.headers.set(pair[0], pair[1])
    }

    return res
  }

  const instantiateArrayBuffer = (receiver, binary, info) => {
    return WebAssembly.instantiate(binary, info).then((module) => {
      receiver(module.instance, Module)
    }, function (reason) {
      console.error('[Loader] failed to asynchronously prepare wasm: ' + reason)

      // Warn on some common problems.
      // if (isFileURI(wasmBinaryFile)) {
      // 	console.log('warning: Loading from a file URI (' + wasmBinaryFile + ') is not supported in most browsers. See https://emscripten.org/docs/getting_started/FAQ.html#how-do-i-run-a-local-webserver-for-testing-why-does-my-program-stall-in-downloading-or-preparing');
      // }
      throw new Error(reason)
    })
  }

  const instantiateWASM = async (info, receiveInstance, useCompression = false) => {
    // Hack: Assume the wasm is gzipped if the port is empty
    // TODO: Find a better solution for this, maybe have another .html entrypoint??
    let compressionExt = ''
    if (useCompression) {
      compressionExt = '.gz'
    }

    try {
      const wasmBinaryPath = `${rootWasmPath}Hiberworld.wasm${compressionExt}`

      if (location.protocol === 'file:') {
        const wasmBinary = await fetchXMLHTTPRequest(wasmBinaryPath, (progressFraction) => {
          // document.getElementById('loader-status').textContent = `Loading wasm ${Math.round(progressFraction*100)}%`;
        })

        // Add arguments
        Module.wasmBinary = wasmBinary

        return instantiateArrayBuffer(receiveInstance, wasmBinary, info)
      } else {
        const response = progressResponse(await fetch(wasmBinaryPath, { credentials: 'same-origin' }), (loaded, total) => {
          // document.getElementById('loader-status').textContent = `Loading wasm ${Math.round((loaded / total)*100)}%`;
        })

        if (typeof WebAssembly.instantiateStreaming === 'function') {
          console.log('[Loader] Using streaming instantiation')
          return WebAssembly.instantiateStreaming(response, info).then((module) => {
            receiveInstance(module.instance, Module)
          }).catch(async (reason) => {
            // We expect the most common failure cause to be a bad MIME type for the binary,
            // in which case falling back to ArrayBuffer instantiation should work.

            if (response.bodyUsed) {
              throw new Error('Body already used, probably due to main() failing, aborting...')
            }

            console.warn('[Loader] wasm streaming compile failed: ' + reason)
            console.warn('[Loader] falling back to ArrayBuffer instantiation')
            const binary = await response.arrayBuffer()
            return instantiateArrayBuffer(receiveInstance, binary, info)
          }).catch((err) => {
            throw err
          })
        } else {
          console.warn('[Loader] using ArrayBuffer instantiation')
          const binary = await response.arrayBuffer()
          return instantiateArrayBuffer(receiveInstance, binary, info)
        }
      }
    } catch (error) {
      throw error
    }
  }

  let readyFunc
  const readyPromise = new Promise((resolve, reject) => {
    readyFunc = () => {
      resolve()
    }
  })

  var Module = {
    noInitialRun: true, // Lets us call main instead
    arguments: window.WASM_ARGUMENTS || [],
    canvas: canvasEl,
    print: (txt) => {
      if (txt.includes('ERROR')) {
        const error = new Error(txt)
        console.error(error)

        if (window.Sentry) {
          Sentry.captureException(error)
        }
      } else if (txt.includes('WARNING')) {
        console.warn(txt)
      } else {
        console.log(txt)
      }
    },
    printErr: (errTxt) => {
      console.warn(errTxt)
    },
    onRuntimeInitialized: () => {
      console.log('WasmRuntimeInitialized, calling main...')
      // document.getElementById('loader-status').textContent = 'Loaded wasm!';

      // Hide loader
      // document.getElementById("loader-container").hidden = true;

      Module.callMain(window.WASM_ARGUMENTS || Module.arguments)

      // Show canvas
      canvasEl.hidden = false

      // Remove loading text
      if (document.getElementById('loading-text')) {
        document.getElementById('loading-text').remove()
      }

      // TODO: Seems to bug out noesis if done before calling main causing images to overflow outside their containers
      collectWebglInfo()
    },
    onMessage: ({ action, value }) => {
      if (action === 'ready') {
        readyFunc()
      } else if (action === 'track_event' && !window.dont_track) {
        if (window.DiveSDK && DiveSDK.DiveSDK.instance) {
          const diveInstance = DiveSDK.DiveSDK.getInstance()
          const eventData = JSON.parse(value)
          diveInstance.RecordEvent(eventData.eventName, eventData.eventProperties)
        }
      }
    },
    onExit: (status) => {
      console.log('Exit Status', status)
      // document.getElementById('loader-status').textContent = `Exit: ${status}`;

      // Hide canvas
      canvasEl.hidden = true
    },
    onAbort: (what) => {
      // var error = new WebAssembly.RuntimeError(what);
      // console.error('WasmAbort', error);
      // document.getElementById('loader-status').textContent = `Aborted: ${error.stack}`;

      // Hide canvas
      canvasEl.hidden = true

      // Increase log window width
      if (document.getElementById('log')) {
        document.getElementById('log').style.width = '70%'
        document.getElementById('log').style.display = 'block'
      }

      if (window.scrollToBottomLog) {
        scrollToBottomLog()
      }
    },
    postRun: (obj) => {
      console.log('WasmPostRun')
    },
    instantiateWasm: (info, receiveInstance) => {
      const useCompression = location.port === ''

      instantiateWASM(info, receiveInstance, useCompression).then(() => {
        console.log('Successfully instantiated wasm')
      }).catch((err) => {
        console.error(err)

        if (useCompression) {
          // Fallback to try loading uncompressed file
          console.warn('Falling back to loading uncompressed file')
          instantiateWASM(info, receiveInstance, false)
        }
      })
      return {}
    }
  }

  return Hiberworld(Module).then((instance) => {
    /*
		 HBR.worldPlacePrefab("log_01", {
			 position: {
				 x: 0,
				 y: 1,
				 z: 0,
			 },
			 quaternion: {
				 x: 0,
				 y: 0,
				 z: 0,
				 w: 1,
			 },
			 scale: {
				 x: 1,
				 y: 2,
				 z: 1,
			 },
		 })
		*/

    const addTransform = (instance, transform) => {
      const len = 40 // sizeof(HBRTransformParam)
      const ptr = instance.stackAlloc(len)
      instance.setValue(ptr, transform.position.x, 'float')
      instance.setValue(ptr + 4, transform.position.y, 'float')
      instance.setValue(ptr + 8, transform.position.z, 'float')

      instance.setValue(ptr + 12, transform.quaternion.x, 'float')
      instance.setValue(ptr + 16, transform.quaternion.y, 'float')
      instance.setValue(ptr + 20, transform.quaternion.z, 'float')
      instance.setValue(ptr + 24, transform.quaternion.w, 'float')

      instance.setValue(ptr + 28, transform.scale.x, 'float')
      instance.setValue(ptr + 32, transform.scale.y, 'float')
      instance.setValue(ptr + 36, transform.scale.z, 'float')
      return ptr
    }

    const sleep = (ms) => {
      return new Promise(r => setTimeout(r, ms))
    }

    const waitForResourceLoading = async () => {
      while (hasPendingResourceIO()) {
        await sleep(100)
      }
    }

    const worldClear = () => {
      instance.ccall('hbrWorldClear', 'void', [], [])
      return true
    }

    const worldCreateGroup = () => {
      const groupID = instance.ccall('hbrWorldCreateGroup', 'string', [], [])
      return groupID
    }

    const worldCreateObject = (groupID) => {
      if (!groupID) {
        throw new Error('Missing groupID')
      }

      const objectID = instance.ccall('hbrWorldCreateObject', 'string', ['string'], [groupID])
      return objectID
    }

    const worldGetObjectsInGroup = (groupID) => {
      if (!groupID) {
        throw new Error('Missing groupID')
      }

      const objectIDs = JSON.parse(instance.ccall('hbrWorldGetObjectsInGroup', 'string', ['string'], [groupID]))
      return objectIDs
    }

    const worldPlacePrefab = (prefabID, transform) => {
      if (!prefabID) {
        throw new Error('Missing prefabID')
      }

      if (!transform) {
        throw new Error('Missing transform')
      }

      const ptr = addTransform(instance, transform)
      const groupID = instance.ccall('hbrWorldPlacePrefab', 'string', ['string', '*'], [prefabID, ptr])
      return groupID
    }

    const worldMoveGroup = (groupID, targetObjectID, targetTransform) => {
      if (!groupID) {
        throw new Error('Missing groupID')
      }

      if (!targetObjectID) {
        throw new Error('Missing targetObjectID')
      }

      if (!transform) {
        throw new Error('Missing transform')
      }

      const ptr = addTransform(instance, targetTransform)
      instance.ccall('hbrWorldMoveGroup', 'void', ['string', 'string', '*'], [groupID, targetObjectID, ptr])
      return true
    }

    const worldRemoveGroup = (groupID) => {
      if (!groupID) {
        throw new Error('Missing groupID')
      }

      instance.ccall('hbrWorldRemoveGroup', 'void', ['string'], [groupID])
      return true
    }

    const worldChangeEnvironment = (environmentID) => {
      if (!environmentID) {
        throw new Error('Missing environmentID')
      }

      instance.ccall('hbrWorldChangeEnvironment', 'void', ['string'], [environmentID])
      return true
    }

    const fetchAssetManifest = async () => {
      const baseUrl = instance.ccall('hbrGetAssetDBUrl', 'string', [], [])
      const url = `${baseUrl}/bookkeeping/metadata-v2.generated.json`
      const response = await fetch(url)
      const json = await response.json()
      return json
    }

    const worldRayCast = (originWS, dirWS) => {
      const len = 12 // sizeof(HBRFloat3Param)
      const ptr = instance.stackAlloc(len * 2)
      instance.setValue(ptr, originWS.x, 'float')
      instance.setValue(ptr + 4, originWS.y, 'float')
      instance.setValue(ptr + 8, originWS.z, 'float')

      instance.setValue(ptr + 12, dirWS.x, 'float')
      instance.setValue(ptr + 16, dirWS.y, 'float')
      instance.setValue(ptr + 20, dirWS.z, 'float')

      const result = instance.ccall('hbrWorldRayCast', 'number', ['*', '*'], [ptr, ptr + 12])
      return result
    }

    const hasPendingResourceIO = () => {
      const result = instance.ccall('hbrHasPendingResourceIO', 'bool', [], [])
      return result
    }

    const applyPropertyToObject = (objectID, property, persistent) => {
      const wrappedProp = JSON.stringify({ prop: property })
      instance.ccall('hbrApplyPropertyToObject', 'void', ['string', 'string', 'bool'], [objectID, wrappedProp, persistent])
    }

    const applyPropertyToGroup = (groupID, property, persistent) => {
      const wrappedProp = JSON.stringify({ prop: property })
      instance.ccall('hbrApplyPropertyToGroup', 'void', ['string', 'string', 'bool'], [groupID, wrappedProp, persistent])
    }

    const getAvatarPosition = () => {
      const result = instance.ccall('hbrGetAvatarPosition', 'string', [], [])
      const avatarPos = JSON.parse(result)
      return avatarPos
    }

    const getAvatarDirection = () => {
      const result = instance.ccall('hbrGetAvatarDirection', 'string', [], [])
      const avatarDir = JSON.parse(result)
      return avatarDir
    }

    const getCameraPosition = () => {
      const result = instance.ccall('hbrGetCameraPosition', 'string', [], [])
      const camPos = JSON.parse(result)
      return camPos
    }

    const getCameraDirection = () => {
      const result = instance.ccall('hbrGetCameraDirection', 'string', [], [])
      const camDir = JSON.parse(result)
      return camDir
    }

    return readyPromise.then(() => ({
      worldClear,
      worldCreateGroup,
      worldCreateObject,
      worldGetObjectsInGroup,
      worldPlacePrefab,
      worldMoveGroup,
      worldRemoveGroup,
      worldChangeEnvironment,
      fetchAssetManifest,
      worldRayCast,
      waitForResourceLoading,
      hasPendingResourceIO,
      applyPropertyToObject,
      applyPropertyToGroup,
      getAvatarPosition,
      getAvatarDirection,
      getCameraPosition,
      getCameraDirection
    }))
  })
}
