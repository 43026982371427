import Group from "../Group.js";
import Item from "../Item.js";
import ClipObject from "../ClipObject.js";
import _u from "../../_u.js";
import ArtPiece1 from "./ArtPiece.js";
import Mate from "../Mate.js";
import ArtTemple from "./ArtTemple.js";
import { RandomSeed } from "../RandomSeed.js";

export default class ArtCircle extends ClipObject {
  constructor(params) {
    super(params);  
    this.setup();
  }

  setup = () => {

    const radius = _u.defVal(this.params.radius, 10);
    this.userData.props.width = radius * 2;
    let arrOfProps = [];
    let temple = new ArtTemple({size: radius, justFloor: false});

    this.add(temple);

    _u.each(this.params.artData, (ad, index) => {
      const scaleMin = _u.defVal(this.params.scaleMin, 2);
      const scaleMax = _u.defVal(this.params.scaleMax, 3.5);
      const artScale = RandomSeed.range(scaleMin, scaleMax);
      const gapSize = _u.defVal(this.params.gapSize, 14);
      const yPos = artScale / scaleMax; 

      arrOfProps.push({
        p: [-index * gapSize, yPos, 0],
        s: [artScale * ad.ratio, artScale, 1],
        src: ad.src
      })
    })

    Mate.placeInCircle(arrOfProps, radius, (props) => {
      this.add(new ArtPiece1(props));
    })

  };
}