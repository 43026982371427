import * as ethers from 'ethers'
import { isAddress } from './util'

export const resolveAddress = async (address: string): Promise<string> => {
  // const provider = getDefaultProvider()
  const provider = new ethers.providers.AlchemyProvider('homestead', process.env.GATSBY_ALCHEMY_API_KEY)
  // If ethereum address -> return address
  if (isAddress(address)) return address
  // If ENS name -> resolve and return address
  if (address.includes('.eth')) {
    const resolvedAddress = await provider.resolveName(address)
    if (resolvedAddress) return resolvedAddress
  }
  // Fallback -> invalida address
  throw new Error('Invalid address: ' + address)
}
