import React, { useEffect, useRef, useState } from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'
import { isDesktop, isMobile } from 'react-device-detect'
import screenfull from 'screenfull'
import { dispatch } from '../../lib/events'

import AssetInfoMobile from './AssetInfoMobile'
import AssetInfoDesktop from './AssetInfoDesktop'
import StartPrompt from './StartPrompt'
import ExitGallery from './ExitGallery'
import CopyLink from './CopyLink'
import Toast from './Toast'
import Keybinding from './Keybinding'
import Fullscreen from './Fullscreen'

// CSS
import './VirtualCanvas.sass'

const keybindings = [
  ['O', 'View on OpenSea'],
  ['C', 'Copy URL'],
  ['Space', 'Jump'],
  ['R', 'Respawn'],
  ['Esc', 'Show cursor'],
  ['Q', 'Exit gallery']
]

const VirtualCanvas = ({
  address,
  asset,
  experienceActive,
  loading,
  showAssetInfo,
  showKeybindings,
  showStart
}) => {
  // React hooks
  const [keyPressed, setKeyPressed] = useState(null)
  const [fullscreen, setFullscreen] = useState(false)
  const [fullscreenEnabled, setFullscreenEnabled] = useState(false)
  const toast: React.MutableRefObject<any> = useRef()

  // User interactions
  const actions = {
    quit: () => {
      dispatch('action_quit')
      window.open('/', '_self')
    },
    copyLink: async () => {
      // If clipboard API exposed ->
      if (navigator?.clipboard?.writeText) {
        // 1) Copy URL to clipboard
        const url = document.location.href
        await navigator.clipboard.writeText(document.location.href)
        // 2) Trigger toast
        toast.current.trigger()
        // 3) Dispatch event
        dispatch('action_share', { url, address })
      }
    },
    viewOnOpensea: () => {
      // If no asset within range, do nothing
      if (!showAssetInfo) return

      // Open Opensea permalink in new tab/window
      window.open(asset.permalink, '_blank')

      // Track event
      dispatch('action_opensea', {
        asset_name: asset.name,
        asset_collection: asset.collection,
        asset_id: asset.id,
        asset_url: asset.permalink
      })
    }
  }

  // On first render
  useEffect(() => {
    // Setup keybindings
    document.addEventListener('keypress', async e => setKeyPressed(e.code))

    // Handle fullscreen
    if (screenfull.isEnabled) {
      setFullscreenEnabled(true)
      screenfull.on('change', () => setFullscreen(screenfull.isFullscreen))
    }
  }, [])

  // Handle key press
  const handleKeyPress = async () => {
    // [O] - Opensea
    if (keyPressed === 'KeyO') actions.viewOnOpensea()

    // [C] - Copy link
    if (keyPressed === 'KeyC') actions.copyLink()

    // [Q] - Quit
    if (keyPressed === 'KeyQ') {
      [
        document.querySelector('#canvas'),
        document.querySelector('#virtual-canvas')
      ].forEach(el => el.classList.add('fade'))

      // TODO: Use Gatsby function 'navigate' instead
      // Due to tight deadline, didn't have time to add proper
      // clean up of eventlisteners etc. to prevent poor performance
      // and bugs when reopening a page through internal navigation
      actions.quit()
      // navigate('/')
    }
    // Reset text pressed
    setKeyPressed(null)
  }
  if (keyPressed) handleKeyPress()

  const keyBindingDiv =
    <div className="keybindings">
      {keybindings.map(([character, legend], index) => (
        <Keybinding key={index} character={character} legend={legend} />
      ))}
    </div>

  const loadingDiv =
    <div id="loading" hidden={loading}>
      <img src="/images/powered_by_hiberworld.svg" />
    </div>

  const assetInfoProps = {
    imageUrl: asset.imageUrl,
    name: asset.name,
    collection: asset.collection,
    onTap: actions.viewOnOpensea
  }

  const assetInfo = isMobile
    ? <AssetInfoMobile {...assetInfoProps} />
    : <AssetInfoDesktop {...assetInfoProps} />

  return (
    <>
      {/* Virtual canvas for HTML elements on top of 3D experience */}
      <div id="virtual-canvas">

        {/* Key bindings */}
        { isDesktop && showKeybindings ? keyBindingDiv : null}

        {/* Asset info */}
        { showAssetInfo ? assetInfo : null }

        {/* Click/tap to start */}
        { experienceActive || !showStart ? null : <StartPrompt /> }

        {/* Toast */}
        <Toast iconPath="/icons/check.svg" text="Gallery link copied!" ref={toast} />

        {/* Mobile only */}
        { isMobile
          ? <>
              {/* ICON: Exit gallery */}
              <ExitGallery onTap={actions.quit}/>

              {/* ICON: Copy link */}
              <CopyLink onTap={actions.copyLink}/>

              {/* ICON: Toogle fullscreen */}
              { fullscreenEnabled
                ? <Fullscreen fullscreen={fullscreen} onTap={() => screenfull.toggle()} />
                : null
                }
            </>
          : null }

      </div>

      {/* Loading screen */}
      { loading ? loadingDiv : null}
    </>
  )
}

VirtualCanvas.propTypes = {
  address: PropTypes.string,
  asset: PropTypes.object,
  experienceActive: PropTypes.bool,
  loading: PropTypes.bool,
  showKeybindings: PropTypes.bool,
  showStart: PropTypes.bool,
  showAssetInfo: PropTypes.bool
}

export default VirtualCanvas
