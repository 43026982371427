import Group from "../Group.js";
import Item from "../Item.js";
import ClipObject from "../ClipObject.js";
import Spotlight from "./Spotlight.js";
import _u from "../../_u.js";

export default class ArtPiece1 extends ClipObject {
  constructor(params) {
    super(params);  
    this.setup();
  }

  setup = () => {

    // easel_01: {},
    // podium_01: {},
    // redcarpetfence_01: {},

    const frames = {
      woodenframe_01: {
        p: [0, 1, 0.5], 
        s: [2.3, 2.8, 10]
      },
      stoneframe_01: {
        p: [0, 1, 0.3], 
        s: [3, 2.1, 8]
      },
      modernframe_01: {
        p: [0, 1, 0.3], 
        s: [2.2, 4, 8]
      },
      simplegoldenframe_01: {
        p: [0, 1, 0.2], 
        s: [3, 1.8, 10]
      },
    };
    const currentFrames = _u.randFromArray(['simplegoldenframe_01', 'stoneframe_01', 'modernframe_01']);
    // const currentFrames = _u.randFromArray(['simplegoldenframe_01']);

    // t_paper_01
    // let back = new Item({prefab: 'cube_01', name: 'Back', p: [0, 0, 0.01], s: [1 * 1.03, 1 * 1.03, 0.001]});
    // this.add(back) 
    
    if(this.params.src) {
      let img = new Item({prefab: 'cube_01', imgSrc: this.params.src, name: 'Image', p: [0, 0, 0], s: [1, 1, 0.001]});
      this.add(img) 
    }

    let frame = new Item({prefab: currentFrames, name: 'frame', p: frames[currentFrames].p, s: frames[currentFrames].s, r: [0, 180, 0]});
    this.add(frame) 

    let spot = new Spotlight({name: 'Art spot', p: [0, 2.2, -3], s: [0.4], r: [30, 0, 0], justLight: true});
    this.add(spot)

  };
}