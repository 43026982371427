import React from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './CopyLink.module.sass'

const CopyLink = ({ onTap }) => {
  return (
    <div className={styles.box} onTouchEnd={() => onTap()}>
      <img className={styles.icon} src="/icons/copy_link.svg" />
    </div>
  )
}

CopyLink.propTypes = {
  onTap: PropTypes.func
}

export default CopyLink
