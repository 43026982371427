import Group from "../Group.js";
import Item from "../Item.js";
import ClipObject from "../ClipObject.js";
import _u from "../../_u.js";

export default class ClipTiler extends ClipObject {
  constructor(params) {
    super(params);
    this.setup();
  }

  setup = () => {

    const wrap = new Group({
      name: 'wrap'
    });
    this.params.prefab = _u.defVal(this.params.prefab, 'rock_cube_01_t1');
    this.params.width = _u.defVal(this.params.width, 4);
    this.params.depth = _u.defVal(this.params.depth, this.params.width);
    this.params.noX = _u.defVal(this.params.noX, 3);
    this.params.noZ = _u.defVal(this.params.noZ, 3);
    
    const offsetX = this.params.width;
    const offsetZ = this.params.depth;

    for (let indexX = 0; indexX < this.params.noX; indexX++) {
      for (let indexZ = 0; indexZ < this.params.noZ; indexZ++) {
        // console.log([indexX * this.params.width - offsetX, 0, indexZ * this.params.depth - offsetZ]);
        wrap.add(new Item({
          prefab: this.params.prefab,
          name: this.params.name + indexX + '_' + indexZ,
          p: [indexX * this.params.width - offsetX, 0, indexZ * this.params.depth - offsetZ],
          dontMap: this.params.dontMap
        }));
      }
    }
    this.add(wrap)

  };
}