import _u from '../_u.js'
import ClipTiler from './clip/ClipTiler.js'
import Dao from './Dao.js'
import Item from './Item.js'
import { RandomSeed } from './RandomSeed.js'
import Scene from './Scene.js'

export default class PlaceMate {
  static setup () {
    // const env = ['midday_01', 'sunrise_01', 'planet_01', 'above_clouds_01', 'night_clouds_01', 'underwater_01', 'cloud_mountains_01', 'green_clouds_01', 'we_are_friends_01'];
    // const grounds = ['large_grass_plane_01', 'large_grass_plane_02', 'large_jungle_ground_plane_01', 'large_ice_plane_01', 'large_rocky_sand_plane_01', 'large_sand_plane_01', 'large_snow_plane_01', 'large_moon_ground_plane_01', 'large_hex_disco_plane_01'];

    // Environment presets
    const themes = [
      {
        name: 'Midday',
        environment: 'midday_01',
        grounds: 'large_grass_plane_01',
        bigThin1: 'jungle_tree_large',
        bigThin2: 'mossy_rock_01',
        lowThing: 'bush_01',
        weed: 'grass_tuft_02_cluster',
        scale: 1
      },
      {
        name: 'Desert',
        environment: 'sunrise_01',
        grounds: 'large_sand_plane_01',
        bigThin1: 'cliff_pillar',
        bigThin2: 'large_rock_01_t2',
        lowThing: 'en_p_jungle_root_02',
        weed: 'en_p_tumbleweed_01',
        scale: 1
      },
      {
        name: 'Purple day',
        environment: 'above_clouds_01',
        grounds: 'large_grass_plane_02',
        bigThin1: 'jungle_tree_medium',
        bigThin2: 'large_rock_01_t1',
        lowThing: 'bush_01',
        weed: 'cypress_01',
        scale: 1
      },
      {
        name: 'Winter wonderland',
        environment: 'night_clouds_01',
        grounds: 'large_snow_plane_01',
        bigThin1: 'cliff_03_top',
        bigThin2: 'cliff_03_platform',
        lowThing: 'glowing_mushroom',
        weed: 'mushroom_01_t3',
        scale: 1
      },
      {
        name: 'Candy land',
        environment: 'green_clouds_01',
        grounds: 'large_grass_plane_01',
        bigThin1: 'marshmallow_tree_01',
        bigThin2: 'lollipoptree_01',
        lowThing: 'wrapped_candy_01',
        weed: 'bush_01',
        scale: 1
      },
      {
        name: 'City',
        environment: 'underwater_01',
        grounds: 'large_hex_disco_plane_01',
        bigThin1: 'en_m_bu_skyscraper_03_top',
        bigThin2: 'en_m_bu_skyscraper_01_top',
        lowThing: 'streetlight_01',
        weed: 'stone_pine_01',
        scale: 0.6
      }
    ]
    let themeIndex = RandomSeed.range(0, 5)
    if (_u.request('t')) themeIndex = _u.request('t') // You can select environment by adding a &t=<number> to the url

    // Set environment
    Dao.gameApi.worldChangeEnvironment(themes[themeIndex].environment)

    // Place ground
    const ground = new ClipTiler({ // ClipTiler tiles clips in a grid
      name: 'ground block',
      p: [0, -1.9, 0],
      prefab: themes[themeIndex].grounds,
      width: 150,
      noX: 5,
      noZ: 5,
      dontMap: true
    })
    Scene.add(ground)

    // Place sounding stuff

    const place = (prefab, scaleStart, scaleEnd, start, end) => {
      const len = _u.rand(start, end)
      const nPos = _u.rotateAroundAPoint(0, 0, len, 0, _u.rand(0, 360))
      const itm = new Item({
        prefab,
        p: [nPos[0], 0, nPos[1] + 100],
        r: [0, _u.rand(0, 360), 0],
        s: [_u.rand(scaleStart, scaleEnd)]
      })
      Scene.add(itm)
      return itm
    }

    // Place some different stuff in the surrounding

    for (let index = 0; index < 20; index++) {
      // The last two numbers are radius start and end where to place stuff.
      // If things are to close increase the second last.
      place(themes[themeIndex].bigThin1, 1 * themes[themeIndex].scale, 4 * themes[themeIndex].scale, 130, 400)
    }
    for (let index = 0; index < 15; index++) {
      place(themes[themeIndex].bigThin2, 1.5 * themes[themeIndex].scale, 6 * themes[themeIndex].scale, 130, 200)
    }
    for (let index = 0; index < 10; index++) {
      const itm = place(themes[themeIndex].lowThing, 1, 2, 40, 200)
      itm.setTo('p', 'y', -0.7)
    }
    for (let index = 0; index < 30; index++) {
      const itm = place(themes[themeIndex].weed, 2.5, 4.0, 0, 200)
      itm.setTo('p', 'y', -0.5)
    }
  }
}
