import Group from "../Group.js";
import Item from "../Item.js";
import ClipObject from "../ClipObject.js";

export default class Spotlight extends ClipObject {
  constructor(params) {
    super(params);  
    this.setup();
  }

  setup = () => {

    let container;

    if (this.params.justLight) {
      container = new Group({name: 'SpotGroup', p: [0, 0, 0], s: [0.25, 0.33, 0.25]});
    } else {
      container = new Item({prefab: 'bridge_01_cylinder', name: 'cylinder', p: [0, 0, 0], s: [0.25, 0.33, 0.25]});
    }

    const spotlight = new Item({prefab: 'en_p_spotlight_01', name: 'Ball', s: [0.01, 0.01, 0.01], p: [0, 0, 0], r: [0, 0, 180]});
    container.add(spotlight)

    this.add(container)
  
  };
}