import React from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './AssetInfoDesktop.module.sass'

const AssetInfo = ({ imageUrl, name, collection }) => {
  return (
    <div className={styles.assetInfo}>
      <div className={styles.filler}></div>
      <img className={styles.thumbnail} src={imageUrl}></img>
      <div className={styles.text}>
        <div className={styles.name}>{name}</div>
        <div className={styles.collection}>{collection}</div>
      </div>
    </div>
  )
}

AssetInfo.propTypes = {
  collection: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  onTap: PropTypes.func
}

export default AssetInfo
