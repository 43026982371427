import axios from 'axios'
import { fetchAssets } from './nft'
import { getImageRatio } from './util'

export type Art = {
  collection: string,
  description: string,
  id: number,
  imageUrl: string,
  permalink: string,
  ratio: number,
  src: string,
}

export const generateArtData = async (address: string): Promise<Art[]> => {
  // Fetch assets for address
  const assets = await fetchAssets(address)

  // Fetch and format NFT data
  return Promise.all(assets
    .filter(asset => !!asset.imageUrl) // Ensure asset has image url
    .map(async asset => {
      // Get image blob
      const response = await axios.get(asset.imageUrl, { responseType: 'blob' })

      // Create object URL
      const objectUrl = URL.createObjectURL(response.data)

      // Get image ratio
      const ratio = await getImageRatio(objectUrl, asset.id)

      // Push piece to list of art
      return {
        src: objectUrl,
        ratio,
        name: asset.name,
        id: asset.id,
        description: asset.description,
        collection: asset.collection,
        imageUrl: asset.imageUrl,
        permalink: asset.permalink
      }
    }))
}
