import React from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'

// @ts-expect-error Conversion to .css done during build
import * as styles from './AssetInfoMobile.module.sass'

const AssetInfo = ({ name, collection, onTap }) => {
  return (
    <div className={styles.wrapper}>

      {/* Panel */}
      <div className={styles.panel}>

        {/* Asset and collection names */}
        <div className={styles.text}>
          <div className={styles.textName}>{name}</div>
          <div className={styles.textCollection}>{collection}</div>
        </div>

        {/* Link to Opensea */}
        <div
          className={styles.link}
          onTouchEnd={() => onTap()}>
          <div className={styles.linkButton}>View on Opensea</div>
        </div>

      </div>
    </div>
  )
}

AssetInfo.propTypes = {
  name: PropTypes.string,
  collection: PropTypes.string,
  onTap: PropTypes.func
}

export default AssetInfo
