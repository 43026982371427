import React from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './Keybinding.module.sass'

const Keybinding = ({ character, legend }) =>
  <div className={styles.binding}>
    <div className={styles.key}>
      <div className={styles.character}>{character}</div>
    </div>
    <div className={styles.legend}>{legend}</div>
  </div>

Keybinding.propTypes = {
  character: PropTypes.string,
  legend: PropTypes.string
}

export default Keybinding
