import _u from '../_u.js'
import Dao from './Dao.js'
import * as THREE from 'three'

export default class ThreeMate {
  constructor () {
    this.scene = null
    this.renderer = null
    this.camera = null
    this.controls = null
    this.SCREEN_WIDTH = window.innerWidth
    this.SCREEN_HEIGHT = window.innerHeight
    this.lastTime = _u.now()
    this.basicThreeScene()
    this.animate(this.lastTime)
  }

  resize = () => {}

  animate = (now) => {
    requestAnimationFrame(this.animate)

    const dt = now - this.lastTime
    this.lastTime = now
    _u.fire('tick', dt)
    this.update(dt)
  }

  update = (dt) => {
    this.renderer.render(this.scene, this.camera)
  }

  basicThreeScene () {
    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      canvas: document.getElementById('three-canvas')
    })

    this.renderer.setSize(this.SCREEN_WIDTH, this.SCREEN_HEIGHT)
    Dao.mainWrapper.appendChild(this.renderer.domElement)
    this.scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(35, this.SCREEN_WIDTH / this.SCREEN_HEIGHT, 1, 10000)
    window.addEventListener('resize', this.resize, false)
  }

  dispose () {
    // Dispose stuff
  }
}
