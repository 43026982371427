import Group from "../Group.js";
import Item from "../Item.js";
import ClipObject from "../ClipObject.js";
import _u from "../../_u.js";
import ArtPiece1 from "./ArtPiece.js";
import Mate from "../Mate.js";
import ClipTiler from "./ClipTiler.js";
import { RandomSeed } from "../RandomSeed.js";

export default class ArtTemple extends ClipObject {
  constructor(params) {
    super(params);
    this.setup();
  }

  setup = () => {

    const radius = _u.defVal(this.params.radius, 10);
    this.userData.props.width = radius * 2;

    if(!this.params.justFloor) {
      // Dome
      const topStyle = RandomSeed.range(1, 3);
      let top;
      let numberOfPillars = 5;
      let topYPos = null;
      if(topStyle === 1) {
        top = new Item({
          name: 'dome',
          prefab: 'greek_dome_01',
          p: [0, 10, 0],
          s: [this.params.size * 0.3, 4 - this.params.size * 0.05, this.params.size * 0.3]
        })
      }
      if(topStyle === 2) {
        top = new Item({
          name: 'dome',
          prefab: 'greek_roof_01',
          p: [0, 10, 0],
          s: [this.params.size * 0.5, this.params.size * 2, this.params.size * 0.5]
        })
        numberOfPillars = 4;
      }
      if(top) this.add(top);
      
      // Pillar
      const radiusPillar = this.params.size * 1.52;
      const pillarStyle = RandomSeed.range(1, 4);
      let pillarProps;
      let pillarRotationOffset = 90;

      if(pillarStyle === 1) {
        pillarProps = {
          prefab: 'greek_pillar_01',
          p: [radiusPillar, -0.6, 0],
          s: [2.6]
        }
      } 
      if(pillarStyle === 2) { 
        pillarProps = {
          prefab: 'greek_pillar_02',
          p: [radiusPillar, -0.6, 0],
          s: [2.6]
        }
      }
      if(pillarStyle === 3) { 
        pillarProps = {
          prefab: 'greek_window_01',
          p: [radiusPillar, -0.6, 0],
          s: [this.params.size * 0.33],
        }
        pillarRotationOffset = 0;
        topYPos = this.params.size * 2.4;
      }
      if(pillarStyle === 4) { 
        pillarProps = {
          prefab: 'greek_gate_01',
          p: [radiusPillar, -1, 0],
          s: [this.params.size * 0.33],
        }
        pillarRotationOffset = 0;
        topYPos = this.params.size * 2.2;
      }

      const PillArr = [];
      for (let index = 0; index < numberOfPillars; index++) {
        PillArr.push(_u.clone(pillarProps));
      }
      if(pillarStyle !== 3) {
        if(pillarProps) {
          Mate.placeInCircle(PillArr, radiusPillar, (props) => {
            this.add(new Item(props));
          }, {pillarRotationOffset})
        }
      } else {
        if(pillarProps) {
          Mate.placeInCircle(PillArr, radiusPillar, (props) => {
            this.add(new Item(props));
          }, {pillarRotationOffset})

          let offset = 360 / PillArr.length;
          offset *= 0.5;
          Mate.placeInCircle(PillArr, radiusPillar, (props) => {
            props.p[1] += this.params.size * 1.31;
            this.add(new Item(props));
          }, {itemRotationOffset: pillarRotationOffset + offset, circleRotationOffset: offset})

        }
      }

      if(topYPos && top) top.setTo('p', 'y', topYPos)
    }

    // Floor
    const floorBlocks = new ClipTiler({
      name: 'floor block',
      s: [this.params.size * 0.33, 4, this.params.size * 0.33],
      p: [0, -4.4, 0],
      prefab: 'greek_floor_01',
      width: 4,
      noX: 3,
      noZ: 3,
      type: 'temple_floor'
    });
    this.add(floorBlocks);
    
    /*
    broken_greek_pillar
    greek_dome_01
    greek_floor_01
    greek_pillar_01
    greek_pillar_02
    greek_pillar_foot_01
    greek_pillar_roof_01
    greek_roof_01
    greek_stair_01
    greek_wall_01
    greek_window_01
    grass_tuft_02_cluster
    large_rocky_sand_plane_01
    */

  };
}